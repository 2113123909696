import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUtils } from "../utils/Utils";

function ProtectedRoutes() {
  const utils = useUtils();

  // Por el uso del hook de efecto para actualizar variable de autenticación, esta es nula por unos microsegundos. Este código permite una lectura de auth ya con datos de localstorage (actualizados en Authentication.js)
  if (utils.auth === null) {
    return null;
  }

  // Si la usuaria está autenticada, permite acceso al route deseado. De contrário, redirecciona a route / (corresponde a Login.js)
  return utils.auth.loggedIn ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoutes;
