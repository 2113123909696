import React, { useState, useEffect } from "react";
import HttpCommon from "../http-common";
import "../css/AddElements.css";
import ModelBox from "../components/ModelBox";
import ArtisanBox from "../components/ArtisanBox";
import CondBox from "../components/CondBox";
import StatusBox from "../components/StatusBox";
import ColorBox from "../components/ColorBox";
import TypeBox from "../components/TypeBox";
import List from "../components/List";
import Header from "../components/Header";
import { useUtils } from "../utils/Utils";

// Pagina para añadir nuevos elementos en las tablas que tienen relación con la tabla items.

function AddElements() {
  const utils = useUtils();
  const getInitLists = utils.getInitLists;
  const initLists = utils.initLists;

  // Variable que aloja nuevos elementos a agregar a la base de datos.
  const [elements, setElements] = useState([]);

  // Variable con message sobre acción de agregar elementos.
  const [text, setText] = useState("");

  // Variable con información de campos vacíos. Vector de strings.
  const [empty, setEmpty] = useState([]);

  // Variable con información de campos vacíos. Vector de strings.
  const [repeated, setRepeated] = useState([]);

  // Variable con lista de elementos en la base de datos.
  const [displayLists, setDisplayLists] = useState([]);

  // Variable que controla visualización de lista de elementos en la base de datos.
  const [displayStatus, setDisplayStatus] = useState(false);

  // Método que añade nuevos elementos en la variable Elements al clickar en los botones correspondientes.
  const handleClick = (event) => {
    const { name } = event.target;

    // La estructura para modelo es distinta a las demás.
    if (name === "models") {
      setElements((prevElements) => {
        return [
          ...prevElements,
          {
            type: name,
            modelName: "",
            modelDescr: "",
            productType: [],
          },
        ];
      });
    } else {
      setElements((prevElements) => {
        return [
          ...prevElements,
          {
            type: name,
            name: "",
          },
        ];
      });
    }
  };

  // Método que gestiona inputs en cada uno de los componentes específicos de elemento y aloja valor en Elements. Se pasa a los componentes respectivos
  const handleChange = (event, id) => {
    const { name, value } = event.target;

    setElements((prevElements) => {
      let newElements = [...prevElements];

      if (elements[id].type === "models") {
        if (name !== "productType") {
          newElements[id][name] = value;
        }
      } else {
        newElements[id].name = value;
      }
      return newElements;
    });
  };

  // Método remueve caja de elementos de la lista.
  const removeBox = (id) => {
    setElements((prevElements) => {
      const newElements = prevElements.filter((tag, i) => i !== id);
      return newElements;
    });
  };

  // Método que gestiona envío de nueve elementos a la base de datos.
  const handleSubmit = () => {
    // Procedimiento de formatación de campos de texto.
    const submitElements = elements.map((el, i) => {
      let newElement = {};
      if (el.type === "models") {
        newElement = {
          ...el,
          modelName: el.modelName.trim(),
          modelDescr: el.modelDescr.trim(),
        };
      } else {
        newElement = {
          ...el,
          name: el.name.trim(),
        };
      }
      return newElement;
    });

    // Verificar si hay campos vacíos - de ser el caso, no enviar nuevos elementos al servidor node.js y avisar que hay campos vacíos.
    const emptyElements = submitElements
      .map((el, i) => {
        if (
          el.type === "models" &&
          (!el.modelName || !el.modelDescr || el.productType.length === 0)
        ) {
          return { message: `El item #${i + 1} tiene campos vacíos.`, id: i };
        } else if (el.type !== "models" && !el.name) {
          return { message: `El item #${i + 1} no tiene nombre.`, id: i };
        } else {
          return null;
        }
      })
      .filter((el) => el !== null);

    setEmpty(emptyElements);

    // Si no hay campos vacíos, enviar datos al servidor node.js
    if (!emptyElements.length) {
      const getRepeatedElements = async () => {
        const value = await Promise.all(
          submitElements.map((el) => {
            if (el.type === "models") {
              return HttpCommon.post("getModelProductCombination", {
                modelName: el.modelName,
                productType: el.productType[0],
              }).then((response) =>
                response.data.length > 0
                  ? `${el.modelName} (${el.productType[0].name})' ya existe en la base de datos`
                  : null
              );
            } else {
              const type =
                el.type === "artisans" || el.type === "colors"
                  ? el.type.slice(0, -1)
                  : el.type;
              return initLists[`${el.type}List`]
                .map((obj) => obj[`${type}Name`].toLowerCase())
                .includes(el.name.toLowerCase())
                ? `'${el.name.toLowerCase()}' ya existe en la base de datos`
                : null;
            }
          })
        );
        return value.filter((el) => el !== null);
      };

      getRepeatedElements().then((res) => {
        setRepeated(res);
        if (!res.length) {
          let requests = [];

          // Separar por tipo de elemento para tener un POST request direccionado a cada tabla.

          const models = submitElements
            .filter((element) => element.type === "models")
            .map((el) => ({
              modelName: el.modelName,
              modelDescr: el.modelDescr,
              modelId: el.productType[0].id,
            }));

          if (models.length > 0) {
            const reqModels = HttpCommon.post("addModels", {
              models: models,
            });
            requests = [...requests, reqModels];
          }

          const artisans = submitElements
            .filter((element) => element.type === "artisans")
            .map((el) => el.name);

          if (artisans.length > 0) {
            const reqArtisans = HttpCommon.post("addArtisans", {
              artisans: artisans,
            });
            requests = [...requests, reqArtisans];
          }

          const conditions = submitElements
            .filter((element) => element.type === "cond")
            .map((el) => el.name);

          if (conditions.length > 0) {
            const reqConditions = HttpCommon.post("addConditions", {
              conditions: conditions,
            });
            requests = [...requests, reqConditions];
          }

          const statuses = submitElements
            .filter((element) => element.type === "status")
            .map((el) => el.name);

          if (statuses.length > 0) {
            const reqStatuses = HttpCommon.post("addStatuses", {
              statuses: statuses,
            });
            requests = [...requests, reqStatuses];
          }

          const colors = submitElements
            .filter((element) => element.type === "colors")
            .map((el) => el.name.toLowerCase());

          if (colors.length > 0) {
            const reqColors = HttpCommon.post("addColors", {
              colors: colors,
            });
            requests = [...requests, reqColors];
          }

          const productType = submitElements
            .filter((element) => element.type === "productType")
            .map((el) => el.name);

          if (productType.length > 0) {
            const reqTypes = HttpCommon.post("addTypes", {
              productType: productType,
            });
            requests = [...requests, reqTypes];
          }

          Promise.all(requests).then((response) => {
            setElements([]);
            setText("Elementos añadidos :)");
            setTimeout(() => {
              setText("");
            }, 4000);
            getInitLists();
          });
        } else {
          setText("Elementos no añadidos :(");
          setTimeout(() => {
            setText("");
          }, 4000);
        }
      });
    } else {
      setText("Elementos no añadidos :(");
      setTimeout(() => {
        setText("");
      }, 4000);
    }
  };

  // Actualizar listado de elementos cada vez que hay cambios en la base de datos.
  useEffect(() => {
    // Método que gestiona actualización de la lista de elementos.
    const handleDisplayLists = () => {
      let newLists = [];
      for (const key in initLists) {
        newLists = [
          ...newLists,
          <List
            list={
              key === "modelsList"
                ? initLists.modelsList.map((el) => {
                    return {
                      ...el,
                      productTypeName: initLists.productTypeList.filter(
                        (obj) => obj.productTypeId === el.productTypeId
                      )[0].productTypeName,
                    };
                  })
                : initLists[key]
            }
            name={key}
            getInitLists={getInitLists}
          />,
        ];
      }
      setDisplayLists(newLists);
    };
    handleDisplayLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initLists]);

  // Método que controla visualización de lista de elementos en la base de datos
  const handleClickDisplayLists = () => {
    setDisplayStatus((prevDisplayStatus) => !prevDisplayStatus);
  };

  return (
    <div className="wrapper-addElements">
      <Header />
      <div className="listElements-addElements">
        <button
          className="showLists-addElements"
          onClick={handleClickDisplayLists}
        >
          {displayStatus ? "Cerrar" : "Mostrar elementos existentes"}
        </button>
        {displayStatus > 0 && (
          <div className="displayLists-addElements">{displayLists}</div>
        )}
      </div>
      <div className="form-addElements">
        <p className="infoText-addElements">
          Utiliza los siguientes botones para añadir elementos. Puedes añadir
          cuantos quieras.
        </p>
        <nav className="menu-addElements">
          <button
            name="models"
            className="btn-menu-addElements"
            onClick={handleClick}
          >
            Adicionar Modelo
          </button>
          <button
            name="artisans"
            className="btn-menu-addElements"
            onClick={handleClick}
          >
            Adicionar Artesana
          </button>
          <button
            name="cond"
            className="btn-menu-addElements"
            onClick={handleClick}
          >
            Adicionar Condición
          </button>
          <button
            name="status"
            className="btn-menu-addElements"
            onClick={handleClick}
          >
            Adicionar Estado
          </button>
          <button
            name="colors"
            className="btn-menu-addElements"
            onClick={handleClick}
          >
            Adicionar Color
          </button>
          <button
            name="productType"
            className="btn-menu-addElements"
            onClick={handleClick}
          >
            Adicionar tipo de producto
          </button>
        </nav>

        <div className="boxWrapper-addElements">
          {elements.length > 0 &&
            elements.map((element, i) => {
              let item = {};
              switch (element.type) {
                case "models":
                  item = (
                    <ModelBox
                      key={i}
                      id={i}
                      removeBox={removeBox}
                      handleChange={handleChange}
                      element={elements[i]}
                      setFunc={setElements}
                    />
                  );
                  break;
                case "artisans":
                  item = (
                    <ArtisanBox
                      key={i}
                      id={i}
                      removeBox={removeBox}
                      handleChange={handleChange}
                      element={elements[i]}
                    />
                  );
                  break;
                case "cond":
                  item = (
                    <CondBox
                      key={i}
                      id={i}
                      removeBox={removeBox}
                      handleChange={handleChange}
                      element={elements[i]}
                    />
                  );
                  break;
                case "status":
                  item = (
                    <StatusBox
                      key={i}
                      id={i}
                      removeBox={removeBox}
                      handleChange={handleChange}
                      element={elements[i]}
                    />
                  );
                  break;
                case "colors":
                  item = (
                    <ColorBox
                      key={i}
                      id={i}
                      removeBox={removeBox}
                      handleChange={handleChange}
                      element={elements[i]}
                    />
                  );
                  break;
                case "productType":
                  item = (
                    <TypeBox
                      key={i}
                      id={i}
                      removeBox={removeBox}
                      handleChange={handleChange}
                      element={elements[i]}
                    />
                  );
                  break;
                default:
                  break;
              }

              return item;
            })}
          {elements.length > 0 && (
            <button className="button-addElements" onClick={handleSubmit}>
              Añadir elementos
            </button>
          )}
        </div>
        <p className="message-addElements">{text}</p>
        {empty.length > 0 && (
          <ul className="empty-addElements">
            {empty.map((el, i) => (
              <li key={i}>{el.message}</li>
            ))}
          </ul>
        )}
        {repeated.length > 0 && (
          <ul className="empty-addElements">
            {repeated.map((el, i) => (
              <li key={i}>{el}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default AddElements;
