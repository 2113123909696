import React from "react";

function TypeBox({ handleChange, removeBox, id, element }) {
  return (
    <div className="box-addElements">
      <h4>Elemento #{id + 1} - Nuevo Tipo de Producto</h4>
      <label>Nombre del tipo de producto:</label>
      <input
        className="box-input"
        type="text"
        onChange={(e) => handleChange(e, id)}
        value={element.name}
      />
      {element.empty && <p>Campo vacío!</p>}
      <button onClick={() => removeBox(id)}>Borrar</button>
    </div>
  );
}

export default TypeBox;
