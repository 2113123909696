import React from "react";

function StatusBox({ handleChange, removeBox, id, element }) {
  return (
    <div className="box-addElements">
      <h4>Elemento #{id + 1} - Nuevo Estado</h4>
      <label>Tipo de estado:</label>
      <input
        className="box-input"
        onChange={(e) => handleChange(e, id)}
        type="text"
        value={element.name}
      />
      {element.empty && <p>Campo vacío!</p>}
      <button onClick={() => removeBox(id)}>Borrar</button>
    </div>
  );
}

export default StatusBox;
