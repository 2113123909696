import React from "react";
import { useState } from "react";
import HttpCommon from "../http-common";
import "../css/InsertItem.css";
import Item from "../components/Item";
import TagsInput from "../components/TagsInput";
import Header from "../components/Header";
import { useUtils } from "../utils/Utils";

// Pagina para inserir item en la base de datos

function InsertItem() {
  const utils = useUtils(); // Recupera variable y metodo de autenticación del contexto

  // Variable para controlo de la información sobre item a ingresar a la base de datos. Objeto con strings y vectores de objetos
  const [item, setItem] = useState({
    model: [],
    artisan: [],
    length: "",
    width: "",
    color: [],
    matPrice: "",
    hours: "",
    price: "",
    cond: [],
    status: [],
    location: "",
    observations: "",
  });

  // Variable que incluye información sobre campos vacíos
  const [arrEmpty, setArrEmpty] = useState([]);

  // Variable que aloja información sobre el último item añadido a la base de datos para su visualización
  const [lastItem, setLastItem] = useState({});

  // Método que gestiona campos en los campos con información a ingresar a la base de datos.
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Formatación de campos de texto con números
    const newValue = utils.formatNumbers(name, value);

    setItem((prevItem) => {
      return {
        ...prevItem,
        [name]: newValue,
      };
    });
  };

  // Formatación adicional de algunos campos para que no existan espacios en blanco al inicio y al final (quizás pueda volverse método reutilizable)
  const formatInput = (event) => {
    const { name } = event.target;
    setItem((prevItem) => {
      return {
        ...prevItem,
        [name]: prevItem[name].trim(),
      };
    });
  };

  // Método que permite remover item de la base de datos
  const removeItem = (index) => {
    // Remove from db
    HttpCommon.delete("removeItem", {
      data: { itemId: lastItem.itemId, filePath: lastItem.filePath },
    }).then(
      // Remove from itemResult list
      setLastItem({})
    );
  };

  // Método que actualiza datos del item

  const updateItemResult = (updateData) => {
    const propArr = ["model", "artisan", "color", "cond", "status"];

    let newState = lastItem;
    for (const key in updateData) {
      if (propArr.includes(key)) {
        const propName = `${key}Name`;
        newState = {
          ...newState,
          [propName]: updateData[key].map((el) => el.name).join(", "),
        };
      } else {
        newState = { ...newState, [key]: updateData[key] };
      }
    }

    setLastItem(newState);
  };

  // Método que convierte nombres a presentar al español para lista de campos vacíos (posible que se pueda separar para reutlizar en otros componentes)
  const ConvertName = (name) => {
    let spanishName = "";

    switch (name) {
      case "model":
        spanishName = "Modelo";
        break;
      case "artisan":
        spanishName = "Artesana";
        break;
      case "cond":
        spanishName = "Condición";
        break;
      case "status":
        spanishName = "Estado";
        break;
      case "width":
        spanishName = "Ancho";
        break;

      case "length":
        spanishName = "Largo";
        break;
      case "price":
        spanishName = "Precio de venta";
        break;
      case "matPrice":
        spanishName = "Precio materiales";
        break;
      case "hours":
        spanishName = "Horas de Trabajo";
        break;
      case "location":
        spanishName = "Ubicación";
        break;
      case "observations":
        spanishName = "Observaciones";
        break;
      case "color":
        spanishName = "Colores";
        break;
      case "image":
        spanishName = "Cargar imagen";
        break;
      default:
        break;
    }
    return spanishName;
  };

  // Variable con imagen del item a subir al servidor
  const [image, setImage] = useState(null);

  // Mensage con estado sobre upload de imagen (cliente)
  const [imageMessage, setImageMessage] = useState({
    message: "Formatos soportados: .jpg/jpeg o .png",
    err: false,
  });

  // Método que gestiona upload de imagen (cliente), redimensiona a las dimensiones pretendidas y guarda en la variable generada para el efecto.
  const handleFileUpload = (e) => {
    const imageFile = e.target.files[0];

    if (
      imageFile &&
      (imageFile.type === "image/jpeg" || imageFile.type === "image/png")
    ) {
      let reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          var MAX_WIDTH = 300;
          var MAX_HEIGHT = 300;

          var width = img.width;
          var height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
            }
          }

          var canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          ctx.canvas.toBlob((blob) => {
            const file = new File([blob], "upload-image", {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setImage(file);
          });
        };
        img.src = event.target.result;
      };

      reader.readAsDataURL(imageFile);
      setImageMessage({ message: "Imagen cargada :)", err: false });
    } else {
      setImageMessage({
        message: "Imagen no cargada o formato de imagen no soportado.",
        err: true,
      });
      setTimeout(() => {
        setImageMessage({
          message: "Formatos soportados: .jpg/jpeg o .png",
          err: false,
        });
      }, 5000);
      setImage(null);
    }
  };

  // Método que sube datos del item e imagen al servidor

  const addItem = () => {
    setLastItem({});

    // Verifica si hay campos vacíos en item
    let arr = [];
    for (const key in item) {
      if (!item[key].length && key !== "observations" && key !== "location")
        arr = [...arr, key];
    }

    // Guarda nombre del campo vacío en la variable ArrEmpty
    setArrEmpty(arr);
    // If there are empty fields - display item not inserted and empty fields by property name

    let data = new FormData();

    // Si no hay campos vacíos, preparar datos para enviar al servidor node.js, guardándolos en un objeto FormData.

    if (arr.length === 0) {
      for (const element in item) {
        if (!Array.isArray(item[element]) && element !== "color") {
          data.append(element, item[element]);
        } else if (element !== "color") {
          data.append(element, item[element][0].id);
        }
      }

      if (image) data.append("image", image);

      if (utils.auth.username) data.append("inputUser", utils.auth.username);

      // Enviar datos al servidor node.js
      HttpCommon.post("addItem", data, {
        headers: { "content-type": "multipart/form-data" },
      }).then((response) => {
        // Actualizar variables con imagen cargada y mensaje.
        setImage(null);
        setImageMessage((prevImageMessage) => ({
          ...prevImageMessage,
          message: "Formatos soportados: .jpg/jpeg o .png",
        }));

        // Recuperar ID del último item inserido para añadir correspondencia de colores y recuperar último item d ela base de datos para visualización
        const lastId = response.data.insertId;

        // Actualizar tabla de correspondencia entre item y colores
        HttpCommon.post("addColorItem", {
          itemId: lastId,
          color: item.color,
        }).then((response) => {
          // Obtener item inserido en la base de datos
          HttpCommon.get("getLastItem", {
            params: { lastId: lastId },
          }).then((response) => {
            setLastItem(response.data);
            setItem({
              model: [],
              artisan: [],
              length: "",
              width: "",
              color: [],
              matPrice: "",
              hours: "",
              price: "",
              cond: [],
              status: [],
              location: "",
              observations: "",
            });
          });
        });
      });
    }
  };

  return (
    <div className="insertItem">
      <Header />
      <div className="addItemBox">
        {/* Los campos modelo, artesana, condición, estado y colores tienen un input personalizado, que utiliza tags. Ver componente TagsInput */}
        {/* Campo modelo */}
        <label className="label-insert">Seleccionar Modelo:</label>
        <TagsInput name="model" tags={item.model} setFunc={setItem} />

        {/* Campo nombre artesana */}
        <label className="label-insert">Seleccionar Artesana:</label>
        <TagsInput name="artisan" tags={item.artisan} setFunc={setItem} />

        {/* Campo dimensión - largo */}
        <label className="label-insert">Largo (cm):</label>
        <input
          className="input-insert"
          type="text"
          name="length"
          value={item.length}
          onChange={handleChange}
          maxLength="6"
          autoComplete="off"
        />

        {/* Campo dimensión - ancho */}
        <label className="label-insert">Ancho (cm):</label>
        <input
          className="input-insert"
          type="text"
          name="width"
          value={item.width}
          onChange={handleChange}
          maxLength="6"
        />

        {/* Campo colores */}
        <label className="label-insert">Colores:</label>
        <TagsInput name="color" tags={item.color} setFunc={setItem} />

        {/* Campo precio de los materiales */}
        <label className="label-insert">Precio materiales:</label>
        <input
          className="input-insert"
          type="text"
          name="matPrice"
          value={item.matPrice}
          onChange={handleChange}
          maxLength="6"
        />

        {/* Campo horas de trabajo */}
        <label className="label-insert">Horas de trabajo:</label>
        <input
          className="input-insert"
          type="text"
          name="hours"
          value={item.hours}
          onChange={handleChange}
          maxLength="4"
        />

        {/* Campo PVP item */}
        <label className="label-insert">Precio de venta:</label>
        <input
          className="input-insert"
          type="text"
          name="price"
          value={item.price}
          onChange={handleChange}
          maxLength="7"
        />

        {/* Campo condición del item */}
        <label className="label-insert">Condición:</label>
        <TagsInput name="cond" tags={item.cond} setFunc={setItem} />

        {/* Campo estado del item */}
        <label className="label-insert">Estado:</label>
        <TagsInput name="status" tags={item.status} setFunc={setItem} />

        {/* Campo ubicación item */}
        <label className="label-insert">Ubicación:</label>
        <input
          className="input-insert"
          type="text"
          name="location"
          value={item.location}
          onChange={handleChange}
          onBlur={formatInput}
        />

        {/* Campo observaciones */}
        <label className="label-insert">Observaciones:</label>
        <input
          className="input-insert"
          type="text"
          name="observations"
          value={item.observations}
          onChange={handleChange}
          onBlur={formatInput}
        />

        {/* Campos para cargar imagen e mensajes */}
        <div className="image-upload-insert">
          <label className="label-insert">Cargar imagen:</label>
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            className="imageInput-insert"
            onChange={handleFileUpload}
          />
          {image && (
            <div className="image-wrapper-insert">
              <img src={URL.createObjectURL(image)} alt="Uploaded" />
            </div>
          )}

          <p
            className={`imageMessage-insert ${
              imageMessage.err && "imageMessage-err-insert"
            }`}
          >
            {imageMessage.message}
          </p>
        </div>

        <button className="btn-insert" onClick={addItem}>
          Adicionar item
        </button>
      </div>

      {/* Definición mensajes de error (campos vacíos) o visualización de item subido a la base de datos */}
      {arrEmpty.length > 0 && (
        <div className="error-insert">
          <p>Los siguientes campos obligatorios están vacíos:</p>
          <ul className="errorFields-insert">
            {arrEmpty.map((el, i) => (
              <li key={i}>{ConvertName(el)}</li>
            ))}
          </ul>
          <p>Item no inserido en la base de datos.</p>
        </div>
      )}
      {Object.keys(lastItem).length > 0 && (
        <div className="success-insert">
          <p className="successMessage-insert">Item inserido con succeso.</p>
          <Item
            item={lastItem}
            index={0}
            removeItem={removeItem}
            initLists={utils.initLists}
            updateItemResult={updateItemResult}
            type="insert"
          />
        </div>
      )}
    </div>
  );
}

export default InsertItem;
