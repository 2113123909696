import React from "react";
import TagsInput from "./TagsInput";
import { useUtils } from "../utils/Utils";

function ModelBox({ element, removeBox, handleChange, id, setFunc }) {
  const utils = useUtils();

  // Método que guarda la opción seleccionada en el caso del tipo de producto en addElements.
  const handleOptions = (option) => {
    setFunc((prevState) => {
      let newElements = [...prevState];
      newElements[id].productType = [
        {
          name: option.productTypeName,
          id: option.productTypeId,
        },
      ];
      return newElements;
    });

    utils.setOptions({});
  };

  // Método que apaga la opción seleccionada. Se utiliza en el componente TagsInput.js
  const removeTag = (name, index) => {
    setFunc((prevState) => {
      let newElements = [...prevState];
      newElements[id].productType = newElements[id].productType.filter(
        (tag, i) => i !== index
      );
      return newElements;
    });
  };

  return (
    <div className="box-addElements">
      <h4>Elemento #{id + 1} - Nuevo modelo</h4>
      <label>Nombre del Modelo:</label>
      <input
        className="box-input"
        name="modelName"
        type="text"
        onChange={(e) => handleChange(e, id)}
        value={element.modelName}
      />
      <label>Descripción del modelo:</label>
      <input
        className="box-input"
        name="modelDescr"
        type="text"
        onChange={(e) => handleChange(e, id)}
        value={element.modelDescr}
      />
      <label>Tipo de producto:</label>
      <TagsInput
        name="productType"
        tags={element.productType}
        handleOptions={handleOptions}
        type="productType"
        removeTag={removeTag}
        id={id}
      />
      {element.empty && <p>Hay campos vacíos!</p>}
      <button onClick={() => removeBox(id)}>Borrar</button>
    </div>
  );
}

export default ModelBox;
