import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./css/App.css";
import InsertItem from "./Pages/InsertItem";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Retrieve from "./Pages/Retrieve";
import ErrorPage from "./Pages/ErrorPage";
import AddElements from "./Pages/AddElements";

import ProtectedRoutes from "./components/ProtectedRoutes";
import { UtilsProvider } from "./utils/Utils";

// Componente inicial - se definen routes

function App() {
  return (
    <UtilsProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            {/* Routes con acceso restringido a utilizadoras autenticadas a través de componente ProtectedRoutes */}
            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/home" element={<Home />} />
              <Route path="/insert" element={<InsertItem />} />
              <Route path="/retrieve" element={<Retrieve />} />
              <Route path="/addElements" element={<AddElements />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </UtilsProvider>
  );
}

export default App;
