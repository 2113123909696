import React, { createContext, useContext, useState, useEffect } from "react";
import HttpCommon from "../http-common.js";

const UtilsContext = createContext(null);

// Componente que maneja autenticación de usuarias

export const UtilsProvider = ({ children }) => {
  // Estado que indica si usuaria está autenticada
  const [auth, setAuth] = useState(null);

  // Función que permite hacer login y logout
  const logIn = (data) => {
    setAuth(data);
  };

  // Autenticación a traves de verificación de token alojado en cookie en el cliente, realizada cada vez que se actualiza una página.
  // Los datos de autenticación quedan alojados en localStorage y persisten con cada actualización.
  useEffect(() => {
    HttpCommon.get("login").then((response) => {
      if (!response.data.auth) {
        logIn({ loggedIn: false, username: "" });
        localStorage.setItem(
          "auth",
          JSON.stringify({ loggedIn: false, username: "" })
        );
      }
    });

    const data = JSON.parse(localStorage.getItem("auth"));
    data ? setAuth(data) : setAuth({ loggedIn: false, username: "" });
  }, []);

  // *** Manejo de menus dropdown y listas de variables de la base de datos ***

  // Estados para alojar variables de la base de datos que será utilizadas en la App

  const [initModelList, setInitModelList] = useState([]);
  const [initArtisanList, setInitArtisanList] = useState([]);
  const [initCondList, setInitCondList] = useState([]);
  const [initStatusList, setInitStatusList] = useState([]);
  const [initColorsList, setInitColorsList] = useState([]);
  const [initProductTypeList, setInitProductTypeList] = useState([]);

  // Se cargan las variables al iniciar la App

  useEffect(() => {
    getInitLists();
  }, []);

  // Función que requiere las variables a la base de datos y las coloca en los estados correspondientes.
  // 5 pedidos, que corresponden a 5 tablas distintas: modelos, artesanas, condiciones, estados, colores, tipo de producto.

  const getInitLists = () => {
    const firstRequest = HttpCommon.get("getModelsList");
    const secondRequest = HttpCommon.get("getArtisansList");
    const thirdRequest = HttpCommon.get("getCondList");
    const fourthRequest = HttpCommon.get("getStatusList");
    const fifthRequest = HttpCommon.get("getColorsList");
    const sixthRequest = HttpCommon.get("getProductTypeList");

    Promise.all([
      firstRequest,
      secondRequest,
      thirdRequest,
      fourthRequest,
      fifthRequest,
      sixthRequest,
    ]).then((response) => {
      const initLists = response.map((res) => {
        return res.data;
      });

      setInitModelList(initLists[0]);
      setInitArtisanList(initLists[1]);
      setInitCondList(initLists[2]);
      setInitStatusList(initLists[3]);
      setInitColorsList(initLists[4]);
      setInitProductTypeList(initLists[5]);
    });
  };

  const initLists = {
    modelsList: initModelList,
    artisansList: initArtisanList,
    condList: initCondList,
    colorsList: initColorsList,
    statusList: initStatusList,
    productTypeList: initProductTypeList,
  };

  // console.log("InitLists: ", initLists);

  // Variable que aloja las opciones disponibles en los campos que dependen de tablas en la base de datos: modelo, artesana, condición, estado, colores y tipo de producto
  const [options, setOptions] = useState({
    id: undefined,
    name: "",
    elements: [],
  });

  // Método que determina las opciones disponibles en los campos, que dependen de variables de la base de datos.
  // Se utiliza en el componente TagsInput.js
  const handleChangeLists = (event, type, id) => {
    const { name, value } = event.target;
    let matches = [];
    let list = [];

    // Dependiendo del nombre del campo, se utiliza la lista inicial de variables correspondiente, que se obtiene a partir de App.js

    switch (name) {
      case "artisan":
        list = [...initArtisanList];
        break;
      case "model":
        // Hay que incluir no solo el nombre, pero también tipo de producto - Puede haber 2 tipos de producto con el mismo nombre
        // list = [...initModelList];
        list = [
          ...initModelList.map((el) => {
            return {
              ...el,
              productTypeName: initProductTypeList.filter(
                (obj) => obj.productTypeId === el.productTypeId
              )[0].productTypeName,
            };
          }),
        ];
        break;
      case "cond":
        list = [...initCondList];
        break;
      case "status":
        list = [...initStatusList];
        break;
      case "productType":
        list = [...initProductTypeList];
        break;
      default:
        list = [...initColorsList];
    }

    // A la medida que la usuaria digita caracteres en el campo, se buscan correspodencias con la info en la lista.
    if (value.length) {
      matches = list.filter((element) => {
        const regex = new RegExp(value, "gi");
        return element[`${name}Name`].match(regex);
      });
    }

    // Se guardan las correspondencias en la variable options.

    if (type === "productType" || type === "item") {
      setOptions({
        id: id,
        name: name,
        elements: matches,
      });
    } else {
      setOptions((prevOptions) => {
        return { ...prevOptions, name: name, elements: matches };
      });
    }
  };

  // Método que guarda la opción seleccionada en la variable item. Se utiliza en el componente TagsInput.js
  const handleOptions = (option, setFunc) => {
    setFunc((prevState) => {
      return {
        ...prevState,
        [options.name]: [
          ...prevState[options.name],
          {
            id: option[`${options.name}Id`],
            name: option[`${options.name}Name`],
          },
        ],
      };
    });
    setOptions({});
  };

  // Método que hace desaparecer al menu de opciones al hacer click fuera de su area. Se utiliza en el componente TagsInput.js
  const handleBlur = () => {
    setTimeout(() => {
      setOptions({});
    }, 200);
  };

  // Método que apaga la opción/las opciones seleccionadas. Se utiliza en el componente TagsInput.js
  const removeTag = (name, index, setFunc) => {
    setFunc((prevState) => {
      return {
        ...prevState,
        [name]: prevState[name].filter((tag, i) => i !== index),
      };
    });
  };

  // Método formatación de campos numéricos (alojados como strings). Aplica a los inputs de texto de insertItem e inputs editables en Item
  const formatNumbers = (name, value) => {
    let newValue = "";
    if (
      (name === "width") |
      (name === "length") |
      (name === "matPrice") |
      (name === "hours") |
      (name === "price")
    ) {
      newValue = value
        .replace(/[^0-9.]/g, "")
        .replace(".", "x")
        .replace(/\./g, "")
        .replace("x", ".");
    } else {
      newValue = value;
    }
    return newValue;
  };

  return (
    /* La variable de autenticación y la función logIn llegan a otros componentes a partir del hook de contexto */
    <UtilsContext.Provider
      value={{
        auth,
        logIn,
        handleChangeLists,
        handleOptions,
        setOptions,
        options,
        initLists,
        handleBlur,
        removeTag,
        getInitLists,
        formatNumbers,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export const useUtils = () => {
  return useContext(UtilsContext);
};
