import HttpCommon from "../http-common";
import React, { useState } from "react";

function List({ list, name, getInitLists }) {
  const [removalMessage, setRemovalMessage] = useState("");

  // Definir variables 'spanishName' y 'type' en función de la variable name en props

  let spanishName = "";
  let type = "";
  switch (name) {
    case "modelsList":
      spanishName = "Modelos";
      type = "model";
      break;
    case "artisansList":
      spanishName = "Artesanas";
      type = "artisan";
      break;
    case "condList":
      spanishName = "Condiciones";
      type = "cond";
      break;
    case "statusList":
      spanishName = "Estados";
      type = "status";
      break;
    case "colorsList":
      spanishName = "Colores";
      type = "color";
      break;
    case "productTypeList":
      spanishName = "Tipo de productos";
      type = "productType";
      break;
    default:
      break;
  }

  // Método que define nombre de tabla y envía nombre de tabla, type y id a borrar a node.js.
  const handleRemove = (el) => {
    let tableName = "";
    switch (type) {
      case "artisan":
        tableName = "artisans";
        break;
      case "productType":
        tableName = "product_type";
        break;
      case "color":
        tableName = "colors";
        break;
      default:
        tableName = type;
    }

    HttpCommon.delete("removeFromList", {
      params: {
        tableName: tableName,
        id: el[`${type}Id`],
        type: type,
      },
    }).then((response) => {
      updateRemovalMessage(response.data);
      getInitLists();
    });
  };

  // Método que actualiza mensage de remoción de acuerdo a lo enviado a partir de node.js
  const updateRemovalMessage = (message) => {
    setRemovalMessage(message);
    setTimeout(() => {
      setRemovalMessage("");
    }, 3000);
  };

  return (
    <div className="list-addElements">
      <h4>{spanishName}</h4>
      <ul>
        {list.map((el, i) => {
          return (
            <li key={i}>
              <span>
                {type === "model"
                  ? `${el.modelName} (${el.productTypeName})`
                  : el[`${type}Name`]}
              </span>
              <span
                className="removeElement-addElements"
                onClick={() => handleRemove(el)}
              >
                &times;
              </span>
            </li>
          );
        })}
      </ul>
      <p className="removalMessage-addElements">{removalMessage}</p>
    </div>
  );
}

export default List;
