import React, { useState } from "react";
import HttpCommon from "../http-common";
import "../css/Item.css";
import TagsInput from "../components/TagsInput";
import { useUtils } from "../utils/Utils";

// Componente con variables y métodos utilizados en la visualiación/edición de cada Item

function Item(props) {
  // Objeto con métodos y variables de contexto
  const utils = useUtils();

  // *** Variables y métodos pasados como props ***

  // Método removeItem
  const removeItem = props.removeItem;

  // Variable con el índice del item en el listado. Siempre es 0 en insertItem (se muestra solo un item).
  const index = props.index;

  // Contiene las variables de item
  const item = props.item;

  // Variable con el tipo de item
  const type = props.type;

  // Guarda en variables los valores del item

  const {
    itemId,
    modelName,
    artisanName,
    length,
    width,
    colorName,
    matPrice,
    hours,
    price,
    condName,
    statusName,
    location,
    observations,
    filePath,
  } = item;

  // Método para actualizar Item (por el momento son diferentes en InsertItem y Retrieve. Ver si hay como unificar y colocar en Utils)
  const updateItemResult = props.updateItemResult;

  // Carga las listas de variables en base de datos a partir del contexto

  const { modelsList, artisansList, condList, statusList, colorsList } =
    utils.initLists;

  // Función que filtra listado de variables de acuerdo al valor en el item y crea un objeto con propiedades id y valor.
  // Se utiliza para definir variable de estado editElement

  const retrieveId = (type, value) => {
    let list = [];

    switch (type) {
      case "model":
        list = modelsList;

        break;
      case "artisan":
        list = artisansList;

        break;
      case "color":
        list = colorsList;

        break;
      case "cond":
        list = condList;

        break;
      case "status":
        list = statusList;

        break;
      default:
        break;
    }

    return list
      .filter((listElm) => value.split(", ").includes(listElm[`${type}Name`]))
      .map((el) => ({
        id: el[`${type}Id`],
        name: el[`${type}Name`],
      }));
  };

  // Variable de estado que determina si se está editando, o no, el Item.
  const [editItem, setEditItem] = useState(false);

  // Variable con datos para edición de Item. Carga los datos atuales y permite almacenar la edición realizada.
  const [editElement, setEditElement] = useState({
    model: { updStatus: false, value: retrieveId("model", modelName) },
    artisan: {
      updStatus: false,
      value: retrieveId("artisan", artisanName),
    },
    length: { updStatus: false, value: length },
    width: { updStatus: false, value: width },
    color: { updStatus: false, value: retrieveId("color", colorName) },
    matPrice: { updStatus: false, value: matPrice },
    hours: { updStatus: false, value: hours },
    price: { updStatus: false, value: price },
    cond: { updStatus: false, value: retrieveId("cond", condName) },
    status: {
      updStatus: false,
      value: retrieveId("status", statusName),
    },
    location: { updStatus: false, value: location },
    observations: { updStatus: false, value: observations },
  });

  // Método que abre/cierra edición de cada elementos del item, cambiando propiedad updStatus que indica si el elemento está en edición
  const handleClickElement = (name) => {
    // Gestiona elementos de dimensión (largo x ancho) en separado porque utilizan el mismo botón.
    if (name === "dimensions") {
      setEditElement((prevEditElement) => {
        return {
          ...prevEditElement,
          length: {
            ...prevEditElement.length,
            updStatus: !prevEditElement.length.updStatus,
          },
          width: {
            ...prevEditElement.width,
            updStatus: !prevEditElement.width.updStatus,
          },
        };
      });
    } else {
      setEditElement((prevEditElement) => {
        return {
          ...prevEditElement,
          [name]: {
            ...prevEditElement[name],
            updStatus: !prevEditElement[name].updStatus,
          },
        };
      });
    }
  };

  // Método que abre/cierra edición de item.
  // Si edición está abierta (editItem === true), al clickar para cerrar se reponen valores iniciales a editElement
  const handleClickItem = () => {
    if (editItem) {
      setEditElement({
        model: {
          updStatus: false,
          value: retrieveId("model", modelName),
        },
        artisan: {
          updStatus: false,
          value: retrieveId("artisan", artisanName),
        },
        length: { updStatus: false, value: length },
        width: { updStatus: false, value: width },
        color: {
          updStatus: false,
          value: retrieveId("color", colorName),
        },
        matPrice: { updStatus: false, value: matPrice },
        hours: { updStatus: false, value: hours },
        price: { updStatus: false, value: price },
        cond: { updStatus: false, value: retrieveId("cond", condName) },
        status: {
          updStatus: false,
          value: retrieveId("status", statusName),
        },
        location: { updStatus: false, value: location },
        observations: { updStatus: false, value: observations },
      });
    }
    setEditItem((prevEditItem) => !prevEditItem);
  };

  // Método que gestiona cambios en los inputs de edición de elementos que no usan tags
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Formatación de campos de texto con números
    const newValue = utils.formatNumbers(name, value);

    setEditElement((prevEditElement) => {
      return {
        ...prevEditElement,
        [name]: {
          ...prevEditElement[name],
          value: newValue,
        },
      };
    });
  };

  // Método que limpia espacios blancos vacíos en los elementos de texto editados que no utilizan tags (ubicación y observaciones).
  // Lógica semejante a formatInput en InsertItem, pero la estructura de editElement es distinta. Mantener métodos separados.
  const formatInput = (event) => {
    const { name } = event.target;
    setEditElement((prevEditElement) => {
      return {
        ...prevEditElement,
        [name]: {
          ...prevEditElement[name],
          value: prevEditElement[name].value.trim(),
        },
      };
    });
  };

  // Método que guarda la opción seleccionada en la variable item. Se utiliza en el componente TagsInput.js
  // Como la estructura de la variable de estado editElement es distinta a las que utilizan el método presente en Utils, mantener acá.

  const handleOptions = (option) => {
    setEditElement((prevEditElement) => {
      return {
        ...prevEditElement,
        [utils.options.name]: {
          ...prevEditElement[utils.options.name],
          value: [
            ...prevEditElement[utils.options.name].value,
            {
              id: option[`${utils.options.name}Id`],
              name: option[`${utils.options.name}Name`],
            },
          ],
        },
      };
    });

    utils.setOptions({});
  };

  // Método que apaga la opción/las opciones seleccionadas. Se utiliza en el componente TagsInput.js
  // Como la estructura de la variable de estado editElement es distinta a las que utilizan el método presente en Utils, mantener acá.

  const removeTag = (name, index) => {
    setEditElement((prevEditElement) => {
      return {
        ...prevEditElement,
        [name]: {
          ...prevEditElement[name],
          value: prevEditElement[name].value.filter((tag, i) => i !== index),
        },
      };
    });
  };

  // Método que actualiza item
  const updateItem = () => {
    // Verificar si hay elementos que actualizar.
    let updateData = {};

    // Crea vector con ids para los colores que corresponden a los que están en el Item
    const currColorId = colorsList
      .filter((el) => colorName.split(", ").includes(el.colorName))
      .map((el) => el.colorId);

    // Hace un loop por editElement, verifica valor actual y valor editado, los compara.
    for (const key in editElement) {
      let currValue = "";
      let newValue = "";
      let comparison = false;

      switch (key) {
        case "model":
          currValue = modelName;
          newValue = editElement[key].value[0].name;
          comparison = currValue === newValue;
          break;
        case "artisan":
          currValue = artisanName;
          newValue = editElement[key].value[0].name;
          comparison = currValue === newValue;
          break;
        case "cond":
          currValue = condName;
          newValue = editElement[key].value[0].name;
          comparison = currValue === newValue;
          break;
        case "color":
          // Compara vectores ordenados de ids de colores (valores actuales y valores editados)
          currValue = currColorId.sort();
          newValue = editElement[key].value.map((elm) => elm.id).sort();
          comparison =
            currValue.length === newValue.length &&
            currValue.every((el, i) => el === newValue[i]);
          break;
        case "status":
          currValue = statusName;
          newValue = editElement[key].value[0].name;
          comparison = currValue === newValue;
          break;
        case "length":
          currValue = length;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        case "width":
          currValue = width;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        case "matPrice":
          currValue = matPrice;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        case "price":
          currValue = price;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        case "hours":
          currValue = hours;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        case "location":
          currValue = location;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        case "observations":
          currValue = observations;
          newValue = editElement[key].value;
          comparison = currValue === newValue;
          break;
        default:
          break;
      }

      // Si los elementos están en modo edición, no están en blanco y son distintos, guardar su valor en el objeto updateData
      if (
        editElement[key].updStatus &&
        editElement[key].value.length > 0 &&
        !comparison
      ) {
        updateData = { ...updateData, [key]: editElement[key].value };
      }
    }

    // Si hay elementos que requieren atualización, preparar datos y enviar al servidor node.js

    if (Object.keys(updateData).length !== 0) {
      // Crea vector con colores a atualizar y objeto elementsUpdate con los demás elementos. Los demás elementos van a la tabla items, colores van a la tabla de unión
      // Una buena parte de esta preparación previa podría hacerse en back-end.
      const { color = [], ...elementsUpdate } = updateData;

      let elementsRequest = {};

      // Loop del objeto con elementos (excepto colores)
      for (const key in elementsUpdate) {
        // Si es vector (caso modelos, artesanas, condiciones y estado), guardar id el objeto elementsRequest
        if (Array.isArray(elementsUpdate[key])) {
          const propName = `${key}Id`;
          elementsRequest = {
            ...elementsRequest,
            [propName]: elementsUpdate[key][0].id,
          };
        } else {
          // Si no es vector (demás elementos), guardar valor en elementsRequest
          elementsRequest = { ...elementsRequest, [key]: elementsUpdate[key] };
        }
      }

      // Vector para acumular envíos a node.js
      let requests = [];

      // Si hay elementos, enviar data para insertar en la tabla items en node.js
      if (Object.keys(elementsRequest).length > 0) {
        const firstRequest = HttpCommon.put("updateItem", {
          elementsRequest: {
            ...elementsRequest,
            lastUpdateUser: utils.auth.username,
          },
          itemId: item.itemId,
        });
        requests.push(firstRequest);
      }

      // Preparar info de colores para enviar

      if (color.length > 0) {
        // Comparar colores de edición y actuales y almacenar los que hay que borrar
        const removeColors = currColorId.filter(
          (el) => !color.map((el) => el.id).includes(el)
        );

        // Enviar colores a borrar a node.js
        if (removeColors.length > 0) {
          const secondRequest = HttpCommon.delete("deleteColors", {
            data: { removeColors: removeColors, itemId: itemId },
          });
          requests.push(secondRequest);
        }
        // Comparar colores de edición y actuales y almacenar los que hay que añadir
        const addColors = color
          .map((el) => el.id)
          .filter((el) => !currColorId.includes(el));

        // Enviar colores a añadir a node.js
        if (addColors.length > 0) {
          const thirdRequest = HttpCommon.post("addToColorsItems", {
            itemId: itemId,
            addColors: addColors,
          });
          requests.push(thirdRequest);
        }
      }

      // Cadena de envíos a node.js
      // Después de todos los envíos, atualizar datos a presentar en la variable de estado itemResult, ubicada en Retrieve.
      Promise.all(requests).then((response) => {
        type === "insert"
          ? updateItemResult(updateData)
          : updateItemResult(index, updateData);
      });
    }

    // Volver a colocar todos los elementos de editElement en modo no editable
    let newEditElm = editElement;
    for (const key in editElement) {
      if (editElement[key].updStatus) {
        newEditElm = {
          ...newEditElm,
          [key]: {
            ...newEditElm[key],
            updStatus: false,
          },
        };
      }
    }
    setEditElement(newEditElm);

    // Cerrar edición
    setEditItem(false);
  };

  return (
    <div className="itemBox">
      <div className="list-item">
        <div className="label-item"># {itemId}</div>
        <div className="artisan-item">
          {editItem ? (
            <>
              <div className="label-item">{artisanName}</div>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("artisan")}
              >
                {editElement.artisan.updStatus ? "Cerrar" : "Editar"}
              </div>
            </>
          ) : (
            <div className="label-item">{artisanName}</div>
          )}
          {editElement.artisan.updStatus && (
            <TagsInput
              name="artisan"
              // options={options}
              // handleChangeLists={handleChangeLists}
              handleOptions={handleOptions}
              tags={editElement.artisan.value}
              removeTag={removeTag}
              edit={true}
              type={"item"}
              id={index}

              // handleBlur={handleBlur}
            />
          )}
        </div>
        <div className="dimensions-item">
          {editItem ? (
            <div>
              <div className="label-item">
                {length} cm X {width} cm
              </div>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("dimensions")}
              >
                {editElement.length.updStatus && editElement.width.updStatus
                  ? "Cerrar"
                  : "Editar"}
              </div>
            </div>
          ) : (
            <div className="label-item">
              {length} cm X {width} cm
            </div>
          )}
          {editElement.length.updStatus && (
            <div>
              <label>L:</label>
              <input
                className="input-item"
                type="text"
                name="length"
                value={editElement.length.value}
                onChange={handleChange}
                maxLength="6"
              />
            </div>
          )}
          {editElement.width.updStatus && (
            <div>
              <label>A:</label>
              <input
                className="input-item"
                type="text"
                name="width"
                value={editElement.width.value}
                onChange={handleChange}
                maxLength="6"
              />
            </div>
          )}
        </div>

        <div className="colors-item">
          {editItem ? (
            <div>
              <div className="label-item">{colorName}</div>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("color")}
              >
                {editElement.color.updStatus ? "Cerrar" : "Editar"}
              </div>
            </div>
          ) : (
            <div className="label-item">{colorName}</div>
          )}
          {editElement.color.updStatus && (
            <div>
              <TagsInput
                name="color"
                handleOptions={handleOptions}
                tags={editElement.color.value}
                removeTag={removeTag}
                edit={true}
                type={"item"}
                id={index}
              />
            </div>
          )}
        </div>

        <div className="prices-item">
          {editItem ? (
            <div>
              <span className="label-item">Materiales: </span>
              <span>{matPrice} $</span>
              <span
                className="editRow-item editItemElm"
                onClick={() => handleClickElement("matPrice")}
              >
                {editElement.matPrice.updStatus ? "Cerrar" : "Editar"}
              </span>
            </div>
          ) : (
            <div>
              <span className="label-item">Materiales: </span>
              <span>{matPrice} $</span>
            </div>
          )}
          {editElement.matPrice.updStatus && (
            <div>
              <input
                className="input-item"
                type="text"
                name="matPrice"
                value={editElement.matPrice.value}
                onChange={handleChange}
                maxLength="6"
              />
            </div>
          )}
          {editItem ? (
            <div>
              <span className="label-item">Horas: </span>
              <span>{hours}</span>
              <span
                className="editRow-item editItemElm"
                onClick={() => handleClickElement("hours")}
              >
                {editElement.hours.updStatus ? "Cerrar" : "Editar"}
              </span>
            </div>
          ) : (
            <div>
              <span className="label-item">Horas: </span>
              <span>{hours}</span>
            </div>
          )}
          {editElement.hours.updStatus && (
            <div>
              <input
                className="input-item"
                type="text"
                name="hours"
                value={editElement.hours.value}
                onChange={handleChange}
                maxLength="4"
              />
            </div>
          )}
          {editItem ? (
            <div>
              <span className="label-item">PVP: </span>
              <span>{price} $</span>
              <span
                className="editRow-item editItemElm"
                onClick={() => handleClickElement("price")}
              >
                {editElement.price.updStatus ? "Cerrar" : "Editar"}
              </span>
            </div>
          ) : (
            <div>
              <span className="label-item">PVP: </span>
              <span>{price} $</span>
            </div>
          )}
          {editElement.price.updStatus && (
            <div>
              <input
                className="input-item"
                type="text"
                name="price"
                value={editElement.price.value}
                onChange={handleChange}
                maxLength="7"
              />
            </div>
          )}
        </div>
        <div className="conditions-item">
          {editItem ? (
            <div>
              <div className="label-item">{condName}</div>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("cond")}
              >
                {editElement.cond.updStatus ? "Cerrar" : "Editar"}
              </div>
            </div>
          ) : (
            <div className="label-item">{condName}</div>
          )}
          {editElement.cond.updStatus && (
            <div>
              <TagsInput
                name="cond"
                handleOptions={handleOptions}
                tags={editElement.cond.value}
                removeTag={removeTag}
                edit={true}
                type={"item"}
                id={index}
              />
            </div>
          )}
          {editItem ? (
            <div>
              <div className="label-item">{statusName}</div>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("status")}
              >
                {editElement.status.updStatus ? "Cerrar" : "Editar"}
              </div>
            </div>
          ) : (
            <div className="label-item">{statusName}</div>
          )}
          {editElement.status.updStatus && (
            <div>
              <TagsInput
                name="status"
                handleOptions={handleOptions}
                tags={editElement.status.value}
                removeTag={removeTag}
                edit={true}
                type={"item"}
                id={index}
              />
            </div>
          )}
        </div>
        <div className="location-item">
          {editItem ? (
            <div>
              <span className="label-item">Ubicación: </span>
              <span>{location}</span>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("location")}
              >
                {editElement.location.updStatus ? "Cerrar" : "Editar"}
              </div>
            </div>
          ) : (
            <div>
              <span className="label-item">Ubicación: </span>
              <span>{location}</span>
            </div>
          )}
          {editElement.location.updStatus && (
            <div>
              <input
                className="input-item"
                type="text"
                name="location"
                value={editElement.location.value}
                onChange={handleChange}
                onBlur={formatInput}
              />
            </div>
          )}
          {editItem ? (
            <div>
              <span className="label-item">Observaciones: </span>
              <span>{observations}</span>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("observations")}
              >
                {editElement.observations.updStatus ? "Cerrar" : "Editar"}
              </div>
            </div>
          ) : (
            <div>
              <span className="label-item">Observaciones: </span>
              <span>{observations}</span>
            </div>
          )}
          {editElement.observations.updStatus && (
            <div>
              <input
                className="input-item"
                type="text"
                name="observations"
                value={editElement.observations.value}
                onChange={handleChange}
                onBlur={formatInput}
              />
            </div>
          )}
        </div>
      </div>

      <div className="secondColumn-item">
        <div className={`model-item ${editItem && "model-edit"}`}>
          {editItem ? (
            <div>
              <div className="label-item">{modelName}</div>
              <div
                className="editItemElm"
                onClick={() => handleClickElement("model")}
              >
                {editElement.model.updStatus ? "Cerrar" : "Editar"}
              </div>
            </div>
          ) : (
            <div className="label-item">{modelName}</div>
          )}
          {editElement.model.updStatus && (
            <TagsInput
              name="model"
              handleOptions={handleOptions}
              tags={editElement.model.value}
              removeTag={removeTag}
              edit={true}
              type={"item"}
              id={index}
            />
          )}
        </div>

        {<img className="image-item" src={filePath} alt="item_image" />}
        <div className="edit-item">
          <button
            className="editButton-item"
            onClick={() => {
              removeItem(index);
            }}
          >
            Borrar
          </button>
          <button className="editButton-item" onClick={handleClickItem}>
            {editItem ? "Cerrar" : "Editar"}
          </button>
          {editItem && (
            <button className="editButton-item" onClick={updateItem}>
              Actualizar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Item;
