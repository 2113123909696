import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png";
import "../css/Header.css";
import HttpCommon from "../http-common";
import { useUtils } from "../utils/Utils";

// Coponente que define encabezado - Aplicado a páginas Home, InsertItem, Retrieve y AddElements

function Header({ type = null }) {
  const utils = useUtils(); // Recupera variable y metodo de autenticación del contexto
  let navigate = useNavigate();

  // Por el uso del hook de efecto para actualizar variable de autenticación, esta es nula por unos microsegundos. Este código permite una lectura de auth ya con datos de localstorage (actualizados en Authentication.js)
  if (utils.auth === null) {
    return null;
  }

  // Método que gestiona logout de usuaria
  const handleLogout = () => {
    utils.logIn({ loggedIn: false, username: "" });
    localStorage.clear();
    // Informa al servidor que debe limpiar cookie con token de autenticación.
    // Axios.get("logout");
    HttpCommon.get("logout");
  };

  return (
    <div className="header">
      {type === "home" ? (
        <div className="welcome">Bienvenida, {utils.auth.username}</div>
      ) : (
        <div
          className="btn-header"
          onClick={() => {
            navigate("/home");
          }}
        >
          Inicio
        </div>
      )}
      {type !== "home" && <img className="logo" src={logo} alt="logo" />}
      <div className="btn-header" onClick={handleLogout}>
        Salir
      </div>
    </div>
  );
}

export default Header;
