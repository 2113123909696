import React from "react";

function CondBox({ handleChange, removeBox, element, id }) {
  return (
    <div className="box-addElements">
      <h4>Elemento #{id + 1} - Nueva Condición</h4>
      <label>Tipo de condición:</label>
      <input
        className="box-input"
        onChange={(e) => handleChange(e, id)}
        type="text"
        value={element.name}
      />
      {element.empty && <p>Campo vacío!</p>}
      <button onClick={() => removeBox(id)}>Borrar</button>
    </div>
  );
}

export default CondBox;
