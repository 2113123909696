import React from "react";

function ArtisanBox({ handleChange, removeBox, id, element }) {
  return (
    <div className="box-addElements">
      <h4>Elemento #{id + 1} - Nueva Artesana</h4>
      <label>Nombre de la artesana: </label>
      <input
        className="box-input"
        type="text"
        onChange={(e) => handleChange(e, id)}
        value={element.name}
      />
      {element.empty && <p>Campo vacío!</p>}
      <button onClick={() => removeBox(id)}>Borrar</button>
    </div>
  );
}

export default ArtisanBox;
