import React from "react";
import HttpCommon from "../http-common";
import { useState } from "react";
import "../css/Retrieve.css";
import TagsInput from "../components/TagsInput";
import Item from "../components/Item";
import Header from "../components/Header";
import { useUtils } from "../utils/Utils";

function Retrieve(props) {
  const utils = useUtils();

  // Variable con los parámetros de búsqueda
  const [itemRequest, setItemRequest] = useState({
    model: [],
    artisan: [],
    cond: [],
    status: [],
    color: [],
  });

  // Variable con los resultados de la búsqueda. Vector de objetos, en dónde cada objeto es un item.
  const [itemResult, setItemResult] = useState([]);
  const [showItems, setShowItems] = useState(false);

  // Variable de control de típo de b+usqueda de colores (true - solo items con todos los colores; false - items con uno de los colores)
  const [allColorsCheck, setAllColorsCheck] = useState(false);

  // Método que envía parámetros al servidor node.js, recupera resultados y los coloca en el vector de objetos itemResult
  const retrieveItems = () => {
    setShowItems(false);
    setAllColorsCheck(false);
    HttpCommon.post("getRequestItems", {
      request: itemRequest,
      allColorsCheck: allColorsCheck,
    }).then((response) => {
      setItemRequest({
        model: [],
        artisan: [],
        cond: [],
        status: [],
        color: [],
      });
      setItemResult([]);

      setItemResult(response.data);
      setShowItems(true);
    });
  };

  // Método que permite remover item de la base de datos.
  // Similar a removeItem en inserItem, pero la estructura es diferente.
  const removeItem = (index) => {
    // Remove from db
    HttpCommon.delete("removeItem", {
      data: {
        itemId: itemResult[index].itemId,
        filePath: itemResult[index].filePath,
      },
    }).then((response) => {
      setItemResult((prevItemResult) =>
        prevItemResult.filter((item, i) => i !== index)
      );
    });
  };

  // Método que controla checkbox del tipo de búsqueda por color
  const handleAllColorsCheck = (event) => {
    setAllColorsCheck((prevAllColorsCheck) => !prevAllColorsCheck);
  };

  // Método que actualiza datos del item
  // Similar al que está en insertItem, pero la estructura de la variable de estado es diferente.
  const updateItemResult = (index, updateData) => {
    const propArr = ["model", "artisan", "color", "cond", "status"];

    const newState = itemResult.map((obj, i) => {
      if (i === index) {
        let newObject = obj;
        for (const key in updateData) {
          if (propArr.includes(key)) {
            const propName = `${key}Name`;
            newObject = {
              ...newObject,
              [propName]: updateData[key].map((el) => el.name).join(", "),
            };
          } else {
            newObject = { ...newObject, [key]: updateData[key] };
          }
        }
        return newObject;
      } else {
        return obj;
      }
    });

    setItemResult(newState);
  };

  return (
    <div className="retrieveItem">
      <Header />
      <div className="box-retrieve">
        <div className="form-retrieve">
          {/* Buscar por modelo */}
          <label className="label-retrieve">Seleccionar modelos: </label>
          <TagsInput
            name="model"
            tags={itemRequest.model}
            setFunc={setItemRequest}
            type="retrieve"
          />

          {/* Buscar por artesana */}
          <label className="label-retrieve">Seleccionar artesanas: </label>
          <TagsInput
            name="artisan"
            tags={itemRequest.artisan}
            type="retrieve"
            setFunc={setItemRequest}
          />

          {/* Buscar condición */}
          <label className="label-retrieve">Seleccionar condiciones: </label>
          <TagsInput
            name="cond"
            tags={itemRequest.cond}
            setFunc={setItemRequest}
            type="retrieve"
          />

          {/* Buscar por estado */}
          <label className="label-retrieve">Seleccionar estados: </label>
          <TagsInput
            name="status"
            tags={itemRequest.status}
            setFunc={setItemRequest}
            type="retrieve"
          />

          {/* Buscar por colores */}
          <label className="label-retrieve">Seleccionar colores: </label>
          <TagsInput
            name="color"
            setFunc={setItemRequest}
            tags={itemRequest.color}
          />

          {/* Seleccionar tipo de búsqueda por colores */}
          <div className="allColors-retrieve">
            <input
              className="allColors-input-retrieve"
              type="checkbox"
              name="allColors"
              checked={allColorsCheck}
              onChange={handleAllColorsCheck}
            />
            <span>
              Apenas items que incluyan todos los colores seleccionados
            </span>
          </div>
        </div>
        <button className="btn-retrieve" onClick={retrieveItems}>
          Buscar items
        </button>
      </div>

      <div className="displayItems-retrieve">
        {showItems && (
          <p className="message-retrieved">
            {itemResult.length} items encontrados
          </p>
        )}
        {/* Mostrar resultados como componente Item, ordenados por ID */}
        {itemResult.length > 0 &&
          itemResult
            .sort((a, b) => b.itemId - a.itemId)
            .map((item, i) => {
              return (
                <Item
                  key={i}
                  index={i}
                  removeItem={removeItem}
                  item={item}
                  initLists={utils.initLists}
                  updateItemResult={updateItemResult}
                  type="retrieve"
                />
              );
            })}
      </div>
    </div>
  );
}

export default Retrieve;
