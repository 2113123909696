import React from "react";
import "../css/ErrorPage.css";
import logo_nf from "../img/logo_nf.png";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  let navigate = useNavigate();

  return (
    <div className="wrapper-ErroPage">
      <img className="logo-ErrorPage" src={logo_nf} alt="logo" />
      <div className="body-errorPage">Página no encontrada</div>
      <div
        className="btn-errorPage"
        onClick={() => {
          navigate("/home");
        }}
      >
        Volver al inicio
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;
