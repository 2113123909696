import React, { useState } from "react";
import "../css/Login.css";
import logo from "../img/logo.png";
import Footer from "../components/Footer";
import HttpCommon from "../http-common";
import { useUtils } from "../utils/Utils";
import { useNavigate } from "react-router-dom";

// Componente de login al que se remiten todas las usuarias no autenticadas.

function Login() {
  const utils = useUtils(); // Variable de contexto con información del componente que maneja autenticación
  const navigate = useNavigate();

  // Variables con datos para autorización y autenticación de usuarias
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Variable con mensages sobre autorización y autenticación de usuarias
  const [loginStatus, setLoginStatus] = useState({
    authorization: "",
    authentication: "",
  });

  // Función que ejecuta autorización y autenticación

  const handleLogin = () => {
    if (username && password) {
      // Verificación de autorización en la base de datos.

      HttpCommon.post("login", {
        username: username,
        password: password,
      }).then((response) => {
        setLoginStatus((prevSetLoginStatus) => {
          return {
            ...prevSetLoginStatus,
            authorization: response.data.message,
          };
        });

        // Autenticación a traves de verificación de token alojado en cookie en el cliente.

        HttpCommon.get("login").then((response) => {
          if (response.data.auth) {
            utils.logIn({ loggedIn: true, username: username });
            localStorage.setItem(
              "auth",
              JSON.stringify({ loggedIn: true, username: username })
            );
            navigate("/home"); // Si la autenticación es correcta, redireccionar a la página de inicio.
          } else {
            setLoginStatus((prevSetLoginStatus) => {
              return {
                ...prevSetLoginStatus,
                authentication: response.data.message,
              };
            });
          }
        });
      });
    } else {
      setLoginStatus((prevSetLoginStatus) => {
        return {
          ...prevSetLoginStatus,
          authorization: "Hay campos vacíos",
        };
      });
    }
  };

  return (
    <div className="wrapper-login">
      <img className="logo-login" src={logo} alt="logo" />
      <div className="box-login">
        <label className="label-login">Usuaria:</label>
        <input
          className="input-login"
          type="text"
          name="username"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
        <label className="label-login">Contraseña:</label>
        <input
          className="input-login"
          type="password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />

        <div className="box-message-login">{loginStatus.authorization}</div>

        <button className="btn-login" onClick={handleLogin}>
          Ingresar
        </button>
      </div>

      <Footer />
    </div>
  );
}

export default Login;
