import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Home.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "../img/logo.png";
import HttpCommon from "../http-common";

// Página de inicio para usuaria autenticada.

function Home() {
  let navigate = useNavigate();

  // Variables para registro de nuevas usuarias
  const [registerStatus, setRegisterStatus] = useState(false); // Estado de registro, permite actualizar info disponibilizada a la usuaria
  const [registerUsername, setRegisterUsername] = useState(""); // Aloja nombre de usuaria
  const [registerPassword, setRegisterPassword] = useState(""); // Aloja contraseña
  const [registerPasswordConf, setRegisterPasswordConf] = useState(""); // Aloja confirmación de contraseña para comparación
  const [registerMessage, setRegisterMessage] = useState(""); // Mensaje sobre registro a presentar a usuaria
  const [errorMessage, setErrorMessage] = useState(false); // Variable para definir colores del mensaje.

  // Método que gestiona estado de registro
  const handleRegisterStatus = () => {
    setRegisterStatus((prevRegisterStatus) => !prevRegisterStatus);
  };

  // Método que gestiona registro
  const handleRegisterSubmit = () => {
    setErrorMessage(false);

    // Si no hay campos vacíos, verificar existnecia de usuaria en la base de datos
    if (registerUsername && registerPassword && registerPasswordConf) {
      HttpCommon.post("checkUser", {
        username: registerUsername,
      }).then((response) => {
        // Si la usuaria existe, indicar. No se registra. Si no existe, comparar contraseñas.
        if (response.data.length > 0) {
          setRegisterMessage("Usuaria ya registrada.");
        } else if (registerPassword === registerPasswordConf) {
          setErrorMessage(true);

          // Si las contraseñas son iguales, enviar información al servidor node.js para procesar y guardar datos de usuaria en la base de datos
          HttpCommon.post("register", {
            username: registerUsername,
            password: registerPassword,
          }).then((response) => {
            setRegisterMessage(response.data);
          });
          setRegisterPassword("");
          setRegisterUsername("");
          setRegisterPasswordConf("");
        } else {
          setRegisterMessage(
            "Las contraseñas no coinciden. Por favor, corregir."
          );
        }
      });
    } else {
      setRegisterMessage("Hay campos vacios");
    }
  };

  return (
    <div className="home">
      <Header type="home" />
      <img className="logo-home" src={logo} alt="logo" />

      {/* Menu de acceso a diferentes funcionalidades de la App */}
      <nav className="nav-home">
        <button
          className="btn-home"
          onClick={() => {
            navigate("/insert");
          }}
        >
          Adicionar Item
        </button>
        <button
          className="btn-home"
          onClick={() => {
            navigate("/retrieve");
          }}
        >
          Consultar Inventario
        </button>
        <button
          className="btn-home"
          onClick={() => {
            navigate("/addElements");
          }}
        >
          Adicionar Elementos
        </button>
      </nav>

      {/* Funcionalidades de registro */}
      <button className="registerBtn-home" onClick={handleRegisterStatus}>
        {registerStatus ? "Cerrar" : "Registrar usuaria"}
      </button>
      {registerStatus && (
        <div className="registerForm-home">
          <label className="registerLabel-home">Nueva usuaria:</label>
          <input
            type="text"
            className="registerInput-home"
            value={registerUsername}
            onChange={(e) => setRegisterUsername(e.target.value)}
          />
          <label className="registerLabel-home">Nueva Contraseña:</label>
          <input
            type="text"
            className="registerInput-home"
            value={registerPassword}
            onChange={(e) => setRegisterPassword(e.target.value)}
          />
          <label className="registerLabel-home">Confirmar Contraseña:</label>
          <input
            type="text"
            className="registerInput-home"
            value={registerPasswordConf}
            onChange={(e) => setRegisterPasswordConf(e.target.value)}
          />
          <button
            className="registerSubmit-home"
            onClick={handleRegisterSubmit}
          >
            Registrar
          </button>
          {registerMessage && (
            <p
              className={`registerMessage-home ${
                errorMessage && "errorMessage-home"
              }`}
            >
              {registerMessage}
            </p>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Home;
