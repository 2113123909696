import React, { useState } from "react";
import "../css/TagsInput.css";
import { useUtils } from "../utils/Utils";

// Componente con lógica de Tags para utilizar en diferentes páginas del aplicativo

function TagsInput({
  name,
  handleOptions = null,
  tags,
  removeTag = null,
  type = "",
  edit = false,
  setFunc = null,
  id = undefined,
}) {
  const utils = useUtils();

  // Definición de spanishName podría reutilizarse a partir de utils

  let spanishName = "";

  switch (name) {
    case "model":
      spanishName = "modelo";
      break;
    case "artisan":
      spanishName = "artesana";
      break;
    case "cond":
      spanishName = "condición";
      break;
    case "status":
      spanishName = "estado";
      break;
    case "productType":
      spanishName = "tipo de producto";
      break;
    default:
      spanishName = "color";
      break;
  }

  // Variable de estado con valor del input del componente
  const [text, setText] = useState(`Adicionar ${spanishName}...`);

  // Método que regresa input al valor inicial
  const resetText = () => {
    setText(`Adicionar ${spanishName}...`);
  };

  // Método que gestiona cambios al valor del input
  const handleChange = (event) => {
    setText(event.target.value);
  };

  // Método que borra valor del input al hacer click en el campo
  const clearText = () => {
    setText("");
  };

  return (
    <div
      className={`tag-wrapper ${
        type === "productType" && "tag-ProductTypeWrapper"
      }`}
    >
      <div className={`tagsInputContainer ${edit && "tag-edit"} `}>
        {tags &&
          tags.map((tag, i) => (
            <div key={i} className="tag">
              <span className="tagName">{tag.name}</span>
              <span
                className="removeTag"
                onClick={() =>
                  type === "productType" || type === "item"
                    ? removeTag(name, i)
                    : utils.removeTag(name, i, setFunc)
                }
              >
                &times;
              </span>
            </div>
          ))}
        {(tags.length < 1 || type === "retrieve" || name === "color") && (
          <input
            className={`${edit ? "input-edit" : "tag-input"}`}
            name={name}
            type="text"
            onChange={(event) => {
              handleChange(event);
              utils.handleChangeLists(event, type, id);
            }}
            value={text}
            onClick={clearText}
            onBlur={utils.handleBlur}
            autoComplete="off"
          />
        )}
      </div>
      <div className={`${edit ? "optionsBox-edit" : "optionsBox-tag"}`}>
        {utils.options.name === name &&
          utils.options.id === id &&
          utils.options.elements.map((option, i) => (
            <div
              className="option-tag"
              key={i}
              onClick={() => {
                resetText();
                type === "productType" || type === "item"
                  ? handleOptions(option)
                  : utils.handleOptions(option, setFunc);
              }}
            >
              {utils.options.name === "model"
                ? `${option.modelName} (${option.productTypeName})`
                : option[`${utils.options.name}Name`]}
            </div>
          ))}
      </div>
    </div>
  );
}

export default TagsInput;
